<template>
  <div class="pa-6">
    <div class="font-weight-medium mb-2">Type 'delete' to confirm</div>
    <v-text-field
      outlined
      placeholder="delete"
      v-model="local.confirm"
      @blur="showErrors.confirm = true"
      @focus="showErrors.confirm = false"
      :error-messages="
        showErrors.confirm && errorMessages.confirm
          ? errorMessages.confirm
          : null
      "
    ></v-text-field>

    <div class="font-weight-medium mb-2">Password</div>
    <v-text-field
      outlined
      type="password"
      v-model="local.password"
      @blur="showErrors.password = true"
      @focus="showErrors.password = false"
      :error-messages="
        showErrors.password && errorMessages.password
          ? errorMessages.password
          : null
      "
    ></v-text-field>

    <div class="d-flex align-center">
      <v-btn
        text
        :disabled="processing"
        depressed
        @click="close"
        >{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >Delete</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserMenuDeleteAccount',
  props: {
    closeLabel: {
      default: 'Close',
    },
  },
  data: function () {
    return {
      disable: false,
      processing: false,
      local: {
        confirm: '',
        password: '',
      },
      showErrors: {
        confirm: false,
        password: false,
      },
    }
  },
  computed: {
    errorMessages() {
      const msgs = {
        confirm: null,
        password: null,
      }

      if (this.local.confirm !== 'delete') {
        msgs.confirm = 'Confirmation is required'
      }

      if (this.local.password === '') {
        msgs.password = 'Please enter the current password'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
  },
  methods: {
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    close() {
      this.$emit('close')
    },
    async submit() {
      this.showErrorsSwitch()

      if (!this.isValid) return

      this.processing = true

      const result = await this.$store.dispatch('account/auth_delete_account', {
        password: this.local.password,
      })

      if (result && result.type === 'success') this.$router.push('/login')

      this.processing = false
      this.$store.commit('app/SET_USER_MENU_ALERT', result)
    },
  },
}
</script>
